<template>
  <MDBContainer>
       <MDBRow>
      <h1>Meet the management team behind TAMIL Crypto</h1>


 
        <MDBCol sm="3">
            <MDBCard>
              <a v-mdb-ripple="{ color: 'light' }">
                <MDBCardImg src="https://mdbootstrap.com/img/new/standard/nature/184.jpg" top alt="..." />
              </a>
              <MDBCardBody>
                <MDBCardTitle>Adviser</MDBCardTitle>
                <MDBCardText>
                  Some quick example text to build on the card title and make up the bulk of the
                  card's content.
                </MDBCardText>
                <MDBBtn tag="a" href="#!" color="primary">LinkedIn</MDBBtn>
              </MDBCardBody>
            </MDBCard>

        </MDBCol>

        <MDBCol sm="3">
            <MDBCard>
              <a v-mdb-ripple="{ color: 'light' }">
                <MDBCardImg src="https://mdbootstrap.com/img/new/standard/nature/184.jpg" top alt="..." />
              </a>
              <MDBCardBody>
                <MDBCardTitle>Adviser</MDBCardTitle>
                <MDBCardText>
                  Some quick example text to build on the card title and make up the bulk of the
                  card's content.
                </MDBCardText>
                <MDBBtn tag="a" href="#!" color="primary">LinkedIn</MDBBtn>
              </MDBCardBody>
            </MDBCard>
          </MDBCol>

        <MDBCol sm="3">
            <MDBCard>
              <a v-mdb-ripple="{ color: 'light' }">
                <MDBCardImg src="https://mdbootstrap.com/img/new/standard/nature/184.jpg" top alt="..." />
              </a>
              <MDBCardBody>
                <MDBCardTitle>Adviser</MDBCardTitle>
                <MDBCardText>
                  Some quick example text to build on the card title and make up the bulk of the
                  card's content.
                </MDBCardText>
                <MDBBtn tag="a" href="#!" color="primary">LinkedIn</MDBBtn>
              </MDBCardBody>
            </MDBCard>
          </MDBCol>

        <MDBCol sm="3">
            <MDBCard>
              <a v-mdb-ripple="{ color: 'light' }">
                <MDBCardImg src="https://mdbootstrap.com/img/new/standard/nature/184.jpg" top alt="..." />
              </a>
              <MDBCardBody>
                <MDBCardTitle>Adviser</MDBCardTitle>
                <MDBCardText>
                  Some quick example text to build on the card title and make up the bulk of the
                  card's content.
                </MDBCardText>
                <MDBBtn tag="a" href="#!" color="primary">LinkedIn</MDBBtn>
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
          
       </MDBRow>

  </MDBContainer>
</template>

<script>
  import { MDBContainer,MDBRow,MDBCol,MDBCard, MDBCardBody, MDBCardTitle, MDBCardText, MDBCardImg, MDBBtn, mdbRipple } from "mdb-vue-ui-kit";

  export default {
    components: {
      MDBCard,
      MDBCardBody,
      MDBCardTitle,
      MDBCardText,
      MDBCardImg,
      MDBBtn,
      MDBCol,
      MDBRow,
      MDBContainer
    },
    directives: {
      mdbRipple
    }
  };
</script>